import React from 'react';

import styles from './google-form.module.css';

import { GoogleFormList, GoogleFormType } from '../config/google-form-conf';

type CustomProps = {
  formType: GoogleFormType;
}

// eslint-disable-next-line
export default class extends React.Component<CustomProps> {

  private readonly frameId: string = 'parent-frame';
  private readonly baseUrl: string = 'https://docs.google.com/forms/d/e/';
  private readonly formDiv: React.RefObject<HTMLDivElement>;

  constructor(props: CustomProps) {
    super(props);

    this.formDiv = React.createRef();
  }

  createForm(formType: GoogleFormType): HTMLIFrameElement {    
    let formIFrame: HTMLIFrameElement = document.createElement('iframe');
    let formInfo = GoogleFormList[formType];
    let src: string = formInfo.id;

    formIFrame.src = this.baseUrl + src + '/viewform';
    formIFrame.id = this.frameId;
    formIFrame.onload = this.changeParentHeight;
    formIFrame.title = formInfo.name;
    formIFrame.width = '100%';
    formIFrame.height = '600';
    formIFrame.frameBorder = '0';
    formIFrame.marginHeight = '0';
    formIFrame.marginWidth = '0';
    formIFrame.scrolling = 'yes';
    formIFrame.className = styles.formIFrame;

    return formIFrame;
  }

  changeParentHeight() {
    let iframeHeight = window.innerHeight - (window.innerHeight / 20);
    let element: HTMLElement | null = document.getElementById(this.frameId);
    if (element !== null) {
      element.setAttribute('height', iframeHeight + 'px');
    }
  }

  render() {
    return (
      <div ref={this.formDiv} className={`${styles.formIFrameWrapper}`}>
      </div>
    );
  }

  componentDidMount() {
    this.reRenderForm();
  }

  componentDidUpdate() {
    this.reRenderForm();
  }

  reRenderForm() {
    if (this.formDiv.current !== null) {
      while(this.formDiv.current.firstChild !== null) {
        this.formDiv.current.removeChild(this.formDiv.current.firstChild);
      }
      this.formDiv.current.appendChild(this.createForm(this.props.formType));
    }
    this.changeParentHeight();
  }
};