import React from 'react';

import About from './pages/about';
import Calendar from './pages/calendar';
import Form from './pages/google-form';
import Contact from './pages/contact';

import AboutEn from './pages/about-en';
import ContactEn from './pages/contact-en';
import PasswordCreator from './pages/password-creator';

export type RouteType = {
  id: string;
  urls: string[];
  component: React.FC | React.ComponentClass;
  isExact: boolean;
};

export const Routes: {[key: string]: RouteType} = {
  About: {
    id: 'about',
    urls: ['/', '/about'],
    component: About,
    isExact: true,
  },
  AboutEn: {
    id: 'about-en',
    urls: ['/en', '/en/about'],
    component: AboutEn,
    isExact: true,
  },
  Calender: {
    id: 'calendar',
    urls: ['/calendar/:id', '/schedule/:id',],
    component: Calendar,
    isExact: true,
  },
  Form: {
    id: 'form',
    urls: ['/form/:id'],
    component: Form,
    isExact: true,
  },
  Contact: {
    id: 'contact',
    urls: ['/contact'],
    component: Contact,
    isExact: true,
  },
  ContactEn: {
    id: 'contact-en',
    urls: ['/en/contact'],
    component: ContactEn,
    isExact: true,
  },
  PasswordCreator: {
    id: 'password-creator',
    urls: ['/app/password-creator', '/app/password-creator/:id'],
    component: PasswordCreator,
    isExact: true,
  },
};