import React from 'react';

import FormStyles from './form.module.css';

import { Language } from '../config/language-conf';

export type AbstractInputProps = React.Props<{}> & {
  props: InnerFormGroupProps & InnerAbstractInputProps & EventHandlerProps;
  className?: string
}

type EventHandlerProps = {
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  onClickDiv?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

type InnerAbstractInputProps = {
  readOnly?: boolean
}

type InnerFormGroupProps = {
  id: string;
  label?: string;
  isRequired?: boolean;
  requiredText?: string;
  lang?: Language;
  annotation?: string | React.ReactElement;
}

export default ((tmpProps: AbstractInputProps) => {

  let innerProps = tmpProps.props;

  let label: React.ReactElement | null = null;
  if (innerProps.label !== undefined) {
    label = <label htmlFor={innerProps.id} className={`${FormStyles.formLabel}`}>{innerProps.label}</label>;
  }

  let required: React.ReactElement | null = null;
  if (innerProps.isRequired !== undefined) {
    let tmpText: string = '';
    if (innerProps.requiredText !== undefined) {
      tmpText = innerProps.requiredText;
    }
    else if (innerProps.lang === Language.JA) {
      tmpText = '(必須)';
    }
    else if (innerProps.lang !== Language.EN) {
      tmpText = '(required)';
    }
    else {
      tmpText = '(必須)';
    }
    required = <span className={`${FormStyles.required}`}>{tmpText}</span>;
  }

  let annotation: React.ReactElement | null = null;
  if (innerProps.annotation !== undefined) {
    annotation = <small className={`${FormStyles.annotation}`}>{innerProps.annotation}</small>;
  }

  let className: string = '';
  if (tmpProps.className !== undefined) {
    className = ' ' + tmpProps.className;
  }

  return (
    <div className={`${FormStyles.formGroup}${className}`}>
      {label}{required}
      {tmpProps.children}
      {annotation}
    </div>
  );
}) as React.FC<AbstractInputProps>;