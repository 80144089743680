import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';

import NormalPageTemplate from '../page-components/normal-page-template';
import styles from './calendar.module.css';

import GoogleCalendarComponent, { GoogleCalendarDisplayMode } from '../page-components/google-calendar';
import { GoogleCalendarType, GoogleCalendarUrlParamList } from '../config/google-calendar-conf';

export default ((props: RouteComponentProps) => {
  const boundary: number = 768;
  const isDesktopOrLaptop = useMediaQuery({ minWidth: boundary })
  const isTabletOrMobile = useMediaQuery({ maxWidth: boundary - 1 });
  let responsiveDisplayMode: GoogleCalendarDisplayMode;
  if (isDesktopOrLaptop) {
    responsiveDisplayMode = GoogleCalendarDisplayMode.MONTH;
  }
  else if (isTabletOrMobile) {
    responsiveDisplayMode = GoogleCalendarDisplayMode.AGENDA;
  }
  else {
    responsiveDisplayMode = GoogleCalendarDisplayMode.AGENDA;
  }

  let calType: GoogleCalendarType | null = null;
  const params: {[key: string]: string} = props.match.params;
  if (GoogleCalendarUrlParamList[params.id] !== null && GoogleCalendarUrlParamList[params.id] !== undefined) {
    calType = GoogleCalendarUrlParamList[params.id]
  }
  else {
    return <Redirect to='/not-found' />
  }

  return (
    <NormalPageTemplate>
      <p className={`${styles.calendarDescription}`}>
      </p>
      <div className={`${styles.calendarComponent}`}>
        <GoogleCalendarComponent calendarType={calType} displayMode={responsiveDisplayMode}/>
      </div>
    </NormalPageTemplate>
  );
}) as React.FC;