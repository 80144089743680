import React from 'react'

import NormalPageTemplate from '../page-components/normal-page-template'

import styles from './password-creator.module.css'

import PasswordInput from '../form-components/password-input'
import TextInput from '../form-components/text-input'
import NumberInput from '../form-components/number-input'
import CheckboxInput from '../form-components/checkbox-input'

const passwordCreatorApiUrl = 'https://api.okinari.com/password-creator'

type CustomProps = {}

type CustomStates = {
	formData: CustomFormStates
	generatePassword: string
	serviceListObj?: { [key: string]: ServiceListInfo }
	serviceListName?: JSX.Element[]
	isOkinariSpecial: boolean
}

type CustomFormStates = {
	password1: string
	password2: string
	serviceName: string
	numberOfDigits: number
	keyword: string
	canUseNumber: boolean
	canUseLowerCaseAlphabet: boolean
	canUseUpperCaseAlphabet: boolean
	usableSpecialChar: string
	[key: string]: string | number | boolean
}

type ServiceListInfo = {
	id: string
	numberOfDigits: CustomFormStates['numberOfDigits']
	canUseNumber: CustomFormStates['canUseNumber']
	canUseLowerCaseAlphabet: CustomFormStates['canUseLowerCaseAlphabet']
	canUseUpperCaseAlphabet: CustomFormStates['canUseUpperCaseAlphabet']
	usableSpecialChar: CustomFormStates['usableSpecialChar']
}

// eslint-disable-next-line
export default class extends React.Component<CustomProps, CustomStates> {

	constructor(props: any) {
		super(props)

		let numberOfDigits: number = 0
		let isOkinariSpecial: boolean = false
		const params: { [key: string]: string } = props.match.params;
		if (params.id === 'is-okinari-special') {
			numberOfDigits = 25
			isOkinariSpecial = true
		}

		this.state = {
			formData: {
				password1: '',
				password2: '',
				serviceName: '',
				numberOfDigits: numberOfDigits,
				keyword: '',
				canUseNumber: true,
				canUseLowerCaseAlphabet: true,
				canUseUpperCaseAlphabet: true,
				usableSpecialChar: '!$%&\'()*+,/;<=>?[]^{}~',
			},
			generatePassword: '',
			isOkinariSpecial: isOkinariSpecial,
		}

		fetch(passwordCreatorApiUrl + '/static/list.json', {mode: 'cors'})
		.then((response: Response) => {
			return response.json()
		})
		.then((jsonData: any) => {
			this.setState({
				serviceListObj: jsonData,
				serviceListName: Object.keys(jsonData).map((key) => (<option key={key} value={key} />)),
			})
		})
	}

	private isSamePassword = () => {
		if (this.state.formData.password1 === this.state.formData.password2) {

		}
	}

	private handlerServiceNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (this.state.isOkinariSpecial) {
			this.setFormValue({ [e.currentTarget.name]: e.currentTarget.value }, () => {
				this.setFormValue({ keyword: 'apps.' + this.state.formData.serviceName + '@okinari.jp' }, this.setValue)
			})
		}
		else {
			this.setFormValue({ [e.currentTarget.name]: e.currentTarget.value }, this.setValue)
		}
	}

	private handlerInputTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setFormValue({ [e.currentTarget.name]: e.currentTarget.value })
	}

	private handlerInputNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setFormValue({ [e.currentTarget.name]: parseInt(e.currentTarget.value) })
	}

	private handlerCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setFormValue({ [e.currentTarget.name]: e.currentTarget.checked })
	}

	private setFormValue(data: { [key: string]: string | number | boolean }, callback?: () => void) {
		const newData = Object.assign({}, this.state.formData)
		for (let key in newData) {
			const tmp = data[key]
			if ((typeof tmp === typeof newData[key])) {
				newData[key] = data[key]
			}
		}
		this.setState({
			formData: newData,
		}, callback)
	}

	private post = () => {
		let formData = this.state.formData
		if (this.state.isOkinariSpecial) {
			formData.password2 = formData.password1
		}
		const body: string = Object.keys(formData).map(key => (key + '=' + encodeURIComponent(formData[key]))).join('&')

		fetch(passwordCreatorApiUrl + '/generate-password', {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
			},
			body: body,
		})
		.then((res: Response) => {
			return res.text()
		})
		.then((textData: string) => {
			let jsonData = JSON.parse(textData)
			this.setState({
				generatePassword: jsonData['message'],
			}/*, () => {console.log(this.state)}*/)
		})
	}

	private setValue = () => {
		if (this.state.serviceListObj === undefined) {
			// console.log('ServiceList has not been obtained yet.')
			return
		}
		if (this.state.formData.serviceName === undefined || this.state.formData.serviceName === '') {
			// console.log('ServiceName is empty.')
			return
		}

		if (this.state.formData.serviceName in this.state.serviceListObj) {
			let tmp = this.state.serviceListObj[this.state.formData.serviceName]
			this.setFormValue({
				numberOfDigits: tmp.numberOfDigits,
				canUseNumber: tmp.canUseNumber,
				canUseLowerCaseAlphabet: tmp.canUseLowerCaseAlphabet,
				canUseUpperCaseAlphabet: tmp.canUseUpperCaseAlphabet,
				usableSpecialChar: tmp.usableSpecialChar,
			})
		}
	}

	public render = () => {
		return (
			<NormalPageTemplate>
				<section className={`${styles.passwordCreator}`}>
					<h1>パスワード生成</h1>
					<p>いろんなサービスのパスワードを別々に覚えたくないあなたへ。</p>

					{/*
          <title>Password Generator</title>
          <meta name="description" content="パスワードを生成します">
          <meta name="keywords" content="パスワード,生成">
          <meta name="viewport" content="width=device-width,user-scalable=no">
          <script type="text/javascript" src="/library/jquery-3.2.1.min.js"></script>
          <script type="text/javascript" src="js/common.js"></script>
          <link type="text/css" rel="stylesheet" href="css/reset.css">
          <link type="text/css" rel="stylesheet" href="css/style.css">
          <link type="text/css" rel="stylesheet" href="css/common.css">
          <link type="text/css" rel="stylesheet" href="css/small.css" media="screen and (max-width:480px)">
          <link type="text/css" rel="stylesheet" href="css/medium.css" media="screen and (min-width:480px) and (max-width:1024px)">
          <link type="text/css" rel="stylesheet" href="css/wide.css" media="screen and (min-width:1024px)"> */}

					<form method="post" action="./">
						<ul className={`${styles.inputForm}`}>
							<li>
								<TextInput
									id="serviceName"
									value={this.state.formData.serviceName}
									onChange={this.handlerServiceNameInputChange}
									onClick={this.setValue}
									list="domain"
									label="サービス名(入力)"
									placeholder="例:okinari.jp ※半角英数字のみ使用可"
								/>
								<datalist id="domain">
									{this.state.serviceListName}
								</datalist>
								<div className="annotation">※パスワードを変換するキーとして利用</div>
							</li>

							<li>
								<NumberInput
									id="numberOfDigits"
									value={this.state.formData.numberOfDigits}
									onChange={this.handlerInputNumberChange}
									label="桁数"
									annotation="※該当サービスのパスワードの上限桁数を入力するといいかと？"
								/>
							</li>
							<li>
								<CheckboxInput
									id="canUseNumber"
									isChecked={this.state.formData.canUseNumber}
									onChange={this.handlerCheckboxChange}
									label="数字"
								/>
							</li>
							<li>
								<CheckboxInput
									id="canUseLowerCaseAlphabet"
									isChecked={this.state.formData.canUseLowerCaseAlphabet}
									onChange={this.handlerCheckboxChange}
									label="英小文字"
								/>
							</li>
							<li>
								<CheckboxInput
									id="canUseUpperCaseAlphabet"
									isChecked={this.state.formData.canUseUpperCaseAlphabet}
									onChange={this.handlerCheckboxChange}
									label="英大文字"
								/>
							</li>
							<li>
								<TextInput
									id="usableSpecialChar"
									value={this.state.formData.usableSpecialChar}
									onChange={this.handlerInputTextChange}
									label="使用可能特殊記号"
									annotation="※そのサービスで使えない文字だけを削除するのがいいかと思います。\n
                  ※使用可能な特殊文字が変わったら、生成される文字列も変わるので注意！\n
                  ※使用可能な特殊文字の順番が変わっても、生成される文字列は変わりません。\n
                  ※上記に初期値として入力されている特殊文字以外は入力しても無効であることも注意！"
								/>
							</li>

							<li>
								<TextInput
									id="keyword"
									value={this.state.formData.keyword}
									onChange={this.handlerInputTextChange}
									label="キーワード(ユーザ名？)"
									placeholder="※半角英数字のみ使用可"
								/>
							</li>

							<li className="">
								<PasswordInput
									id="password1"
									value={this.state.formData.password1}
									onChange={this.handlerInputTextChange}
									label="パスワード"
									placeholder="※半角英数字のみ使用可"
								/>
							</li>
							<li>
								<PasswordInput
									id="password2"
									value={this.state.formData.password2}
									onChange={this.handlerInputTextChange}
									label="パスワード(確認)"
									placeholder="※半角英数字のみ使用可"
								/>
							</li>

							<li>
								<button type="button" name="generate" value="true" onClick={this.post}>パスワード生成</button>
							</li>

							<li>
								<TextInput
									id="generatePassword"
									value={this.state.generatePassword}
									readOnly={true}
									label="生成したパスワード"
								/>
							</li>
						</ul>
					</form>
				</section>
			</NormalPageTemplate>
		)
	}
}
