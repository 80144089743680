import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';

import NormalPageTemplate from '../page-components/normal-page-template';
import styles from './google-form.module.css';

import GoogleFormComponent from '../page-components/google-form';
import { GoogleFormType, GoogleFormUrlParamList } from '../config/google-form-conf';

export default ((props: RouteComponentProps) => {

  let formType: GoogleFormType | null = null;
  const params: {[key: string]: string} = props.match.params;
  if (GoogleFormUrlParamList[params.id] !== null && GoogleFormUrlParamList[params.id] !== undefined) {
    formType = GoogleFormUrlParamList[params.id]
  }
  else {
    return <Redirect to='/not-found' />
  }

  return (
    <NormalPageTemplate>
      <div className={`${styles.formComponent}`}>
        <GoogleFormComponent formType={formType} />
      </div>
    </NormalPageTemplate>
  );
}) as React.FC;