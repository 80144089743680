import React from 'react';
// import { Link } from 'react-router-dom';

// import MediaQuery from 'react-responsive';
// import { useMediaQuery } from 'react-responsive';

import LayoutStyles from './layout.module.css';

// import { MenuListConf } from '../config/menu-list-conf';

import styles from './footer.module.css';

export default (() => {
  // const isFlexDirection = useMediaQuery(
  //   { minWidth: '592px'},
  // );
  // let navMenuStyle: string = styles.navMenuVertically;
  // let navMenuItemStyle: string = styles.navMenuItemVertically;
  // if (isFlexDirection) {
  //   navMenuStyle = styles.navMenuLine;
  //   navMenuItemStyle = styles.navMenuItemLine;
  // }
  return (
      <footer className={`${LayoutStyles.footer} ${styles.footer}`}>
        {/* <MediaQuery minWidth="592px"> */}
          {/* <nav id="footer-nav" className={`${styles.footerNav}`}>
            <ul className={`${styles.navMenu} ${navMenuStyle}`}>
              {
                MenuListConf.map( value => (
                  <li className={`${navMenuItemStyle}`} key={value.id}>
                    <Link to={value.url} className={`${styles.navLink}`}>{value.linkName}</Link>
                  </li>
                ))
              }
            </ul>
          </nav> */}
        {/* </MediaQuery> */}
        {/* <MediaQuery maxWidth="592px">
          <nav id="footer-nav" className={`${styles.footerNav}`}>
            <ul className={`${styles.navMenu}`}>
              {
                MenuListConf.map( value => (
                  <li className={`${styles.navMenuItem}`} key={value.id}>
                    <Link to={value.url} className={`${styles.navLink}`}>{value.linkName}</Link>
                  </li>
                ))
              }
            </ul>
          </nav>
        </MediaQuery> */}
        <small className={`${styles.copyright}`}>
          Copyright &copy; 2020 okinari. All Rights Reserved.
        </small>
      </footer>
  );
}) as React.FC;