import React, { Props, ReactNode } from 'react';

import LayoutStyles from './layout.module.css';

import Header from './header';
import Footer from './footer';
import Advertisement from './advertisement';

import styles from './normal-page-template.module.css';

import { useMediaQuery } from 'react-responsive';

type CustomProps = Props<ReactNode> & {
  isAds?: boolean;
};

export default ((props: CustomProps) => {
  const boundary: number = 768;
  const isDesktopOrLaptop = useMediaQuery({ minWidth: boundary })
  const isTabletOrMobile = useMediaQuery({ maxWidth: boundary - 1 });
  let responsiveClassName: string = '';
  if (isDesktopOrLaptop) {
    responsiveClassName = styles.desktop;
  }
  else if (isTabletOrMobile) {
    responsiveClassName = styles.smartphone;
  }

  let contentsClassName: string = '';
  if (props.isAds) {
    contentsClassName = LayoutStyles.contentsAds;
  }
  else {
    contentsClassName = LayoutStyles.contentsNoAds;
  }

  return (
    <div className={`${LayoutStyles.wrapper}`}>
      <Header />
      <div className={`${contentsClassName} ${styles.contents} ${responsiveClassName}`}>
        {props.children}
      </div>
      {props.isAds ? <Advertisement/> : ''}
      <Footer />
    </div>
  );
}) as React.FC<CustomProps>;