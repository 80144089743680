import React from 'react';

import styles from './google-calendar.module.css';

import { GoogleCalendarList, GoogleCalendarType } from '../config/google-calendar-conf';

import { getRandomString } from '../components/utils'

export enum GoogleCalendarDisplayMode {
  MONTH = 'MONTH',
  AGENDA = 'AGENDA',
}

type CustomProps = {
  calendarType: GoogleCalendarType;
  displayMode: GoogleCalendarDisplayMode;
}

// eslint-disable-next-line
export default class extends React.Component<CustomProps> {

  private readonly frameId: string = 'parent-frame';
  private readonly googleCalendarBaseUrl: string = 'https://calendar.google.com/calendar/embed';
  private readonly calendarParam: { [key: string]: string | number } = {
    title: '',
    showPrint: 0,
    showCalendars: 0,
    height: 600,
    wkst: 1,
    bgcolor: '#ffffff',
    ctz: 'Asia/Tokyo',
    showNav: 1,
    showDate: 1,
    showTabs: 1,
    showTz: 1,
    showTitle: 0,
  };
  private readonly color: string = encodeURIComponent('#A32929');
  private readonly urlParam = Object.entries(this.calendarParam).map(function ([key, val]) {
    return encodeURIComponent(key) + '=' + encodeURIComponent(val);
  }).join('&');
  private readonly calendarDiv: React.RefObject<HTMLDivElement>;

  constructor(props: CustomProps) {
    super(props);

    this.calendarDiv = React.createRef();
  }

  createCalendar(calType: GoogleCalendarType, displayMode: GoogleCalendarDisplayMode): HTMLIFrameElement {
    let scheduleFrame: HTMLIFrameElement = document.createElement('iframe');
    let src: string = GoogleCalendarList[calType].idList.map((val) => {
      return 'src=' + val;
    }).join('&');

    let colors: string = '';
    for (let i = 0, count = GoogleCalendarList[calType].idList.length; i < count; i++) {
      colors += 'color=' + this.color + '&';
    }

    scheduleFrame.src = this.googleCalendarBaseUrl + '?' + src + '&' + colors + this.urlParam + '&mode=' + displayMode;
    scheduleFrame.id = this.frameId;
    scheduleFrame.onload = this.changeParentHeight;
    scheduleFrame.frameBorder = '0';
    scheduleFrame.scrolling = 'no';
    scheduleFrame.nonce = getRandomString()
    scheduleFrame.className = styles.schedule;

    return scheduleFrame;
  }

  changeParentHeight() {
    let iframeHeight = window.innerHeight - (window.innerHeight / 20);
    let element: HTMLElement | null = document.getElementById(this.frameId);
    if (element !== null) {
      element.setAttribute('height', iframeHeight + 'px');
    }
  }

  render() {
    return (
        <div ref={this.calendarDiv} className={`${styles.scheduleWrapper}`}>
          <script>

          </script>
        </div>
    );
  }

  componentDidMount() {
    this.reRenderCalendar();
  }

  componentDidUpdate() {
    this.reRenderCalendar();
  }

  reRenderCalendar() {
    if (this.calendarDiv.current !== null) {
      while (this.calendarDiv.current.firstChild !== null) {
        this.calendarDiv.current.removeChild(this.calendarDiv.current.firstChild);
      }
      this.calendarDiv.current.appendChild(this.createCalendar(this.props.calendarType, this.props.displayMode));
    }
    this.changeParentHeight();
  }
};