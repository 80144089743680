import React from 'react';

import AbstractInput, { AbstractInputProps } from './abstract-input';

import FormStyles from './form.module.css';

type CustomProps = AbstractInputProps['props'] & {
  value?: string;
  placeholder?: string;
}

export default ((props: CustomProps) => {
  return (
    <AbstractInput props={props}>
      <input
        type="password"
        id={props.id}
        name={props.id}
        value={props.value}

        onChange={props.onChange}

        placeholder={props.placeholder}
        className={`${FormStyles.formControll}`}
      />
    </AbstractInput>
  );
}) as React.FC<CustomProps>;