type MenuInfoList = ({
  linkName: string;
  url: string;
  menuList?: undefined;
} | {
  linkName: string;
  menuList: {
      linkName: string;
      url: string;
  }[];
  url?: undefined;
})[]

export const MenuListConf: MenuInfoList = [
  {
    linkName: 'About',
    url: 'https://okinari.com',
  },
  // {
  //   linkName: 'App',
  //   menuList: [
  //     {
  //       linkName: 'PasswordCreator',
  //       url: '/app/password-creator',
  //     },
  //   ],
  // },
  {
    linkName: 'Schedule',
    url: '/schedule/personal',
    // menuList: [
    //   {
    //     linkName: 'Personal',
    //     url: '/schedule/personal',
    //   },
      // {
      //   linkName: 'Events',
      //   url: '/schedule/events',
      // },
    // ],
  },
  // {
  //   linkName: 'Recruit',
  //   menuList: [
  //     {
  //       linkName: 'Personal',
  //       url: '/form/recruit-personal',
  //     },
  //     {
  //       linkName: 'Company',
  //       url: '/form/recruit-company',
  //     },
  //   ],
  // },
  // {
  //   linkName: 'Contact',
  //   url: 'https://okinari.com/contact',
  // },
]

export const MenuListEnConf: MenuInfoList = [
  {
    linkName: 'About',
    url: '/en',
  },
  {
    linkName: 'Schedule',
    url: '/en/schedule/personal',
  },
  {
    linkName: 'EventsCalendar',
    url: '/en/schedule/events',
  },
  {
    linkName: 'Contact',
    url: '/en/contact',
  },
]
