import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';

//import { Router } from 'react-router';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Routes } from './routes';
import NotFound from './pages/not-found';

//import { createBrowserHistory } from 'history';
//const history = createBrowserHistory();

// use Redux
// import store from "./store";
// import {Provider} from "react-redux";

// default
// ReactDOM.render(<App />, document.getElementById('root'));

// use react-router
// ReactDOM.render(
//   <Router history={history}>
//     <Routes />
//   </Router>
//   , document.getElementById('root')
// );
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {
        Object.entries(Routes).map(([key, val]) => (
          val.urls.map(url => (
            <Route
              exact={val.isExact}
              path={url}
              component={val.component}
              key={key}
            />
          ))
        ))
      }
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
  , document.getElementById('root')
);


// use react-router and react-redux
// ReactDOM.render(
//   <Provider store={store}>
//     <Router history={history}>
//       <Routes />
//     </Router>
//   </Provider>
//   , document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
