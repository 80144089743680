import React from 'react';

import AbstractInput, { AbstractInputProps } from './abstract-input';

import FormStyles from './form.module.css';

type CustomProps = AbstractInputProps['props'] & {
  value?: string;
  isChecked?: boolean;
  isDefaultChecked?: boolean;
}

export default ((props: CustomProps) => {
  let value: string = 'true';
  if (props.value !== undefined) {
    value = props.value;
  }

  return (
    <AbstractInput props={props}>
      <input
        type="checkbox"
        id={props.id}
        name={props.id}
        value={value}
        checked={props.isChecked}
        defaultChecked={props.isDefaultChecked}
        onChange={props.onChange}
        className={`${FormStyles.verticalAlign}`}
      />
    </AbstractInput>
  );
}) as React.FC<CustomProps>