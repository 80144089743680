import React from 'react';

import NormalPageTemplate from '../page-components/normal-page-template';

import ImgIcon from '../assets/okinari-icon-circle.png';

import styles from './about.module.css';

export default (() => {
  return (
    <NormalPageTemplate>
      <article className={`${styles.about}`}>
        <h1>About</h1>

        <section className={`${styles.profile}`}>
          <img src={ImgIcon} alt="profile" />
          <h3>こんにちわ</h3>
          <p className={`${styles.sectionContents}`}>
            私は福岡を拠点として活動するエンジニアです。
            主にWebアプリの開発を得意としておりますが、スマホアプリ(モバイルネイティブアプリ)の開発も可能です。
            また、テクニカルアドバイザー、ラジオパーソナリティなどの活動も行っています。
          </p>
        </section>

        {/* <section className={`${styles.whatICanDo}`}>
          <h3>何ができますか?</h3>
          <p className={`${styles.sectionContents}`}>
            システム(アプリ)開発はもちろんのこと、ITツールの活用・導入に関するアドバイス、より効率的な運用方法、技術全般に対するサポート、社内エンジニアの教育など、様々なご依頼を受けることができます。
          </p>
        </section> */}

        <section className={`${styles.iCanHelp}`}>
          <h2>お手伝いできることがありますか？</h2>
          <p className={`${styles.sectionContents}`}>
            私はフリーランスのエンジニアとして活動しておりますので、何かお手伝いできることもあるかもしれません。
          </p>
          <ul className={`${styles.sectionContents}`}>
            <li>自分のアイデアを形にしたい</li>
            <li>ITツールの活用や導入について相談したい</li>
            <li>技術的なアドバイスがほしい</li>
            <li>社員の技術的な教育をしてほしい</li>
            <li>開発を少しだけサポートしてほしい</li>
          </ul>
          <p className={`${styles.sectionContents}`}>
            など、なんでもご相談ください。
          </p>
        </section>
      </article>
    </NormalPageTemplate>
  );
}) as React.FC;