export enum GoogleFormType {
  RecruitCompany,
  RecruitPersonal,
}

// The name(title) is not show
export const GoogleFormList = {
  [GoogleFormType.RecruitCompany]: {
    name: 'RecruitCompany',
    id: '1FAIpQLSddnjb8gpk98uqLbiLJShcUrJeh14aekPnBiKVi7oyGlHUXkw',
  },
  [GoogleFormType.RecruitPersonal]: {
    name: 'RecruitPersonal',
    id: '1FAIpQLSdTU5t1qr9vw7xlTTjTbNEHBvOzy_F6NGL7fAdjmOVJdVwGyA',
  },
}

export const GoogleFormUrlParamList: {[key: string]: GoogleFormType} = {
  'recruit-company': GoogleFormType.RecruitCompany,
  'recruit-personal': GoogleFormType.RecruitPersonal,
}