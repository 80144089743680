import React from 'react';
import { Link } from 'react-router-dom';

import MediaQuery from 'react-responsive';

import LayoutStyles from './layout.module.css';
import styles from './header.module.css';

import { MenuListConf } from '../config/menu-list-conf';

import icon from '../assets/okinari-icon-circle.png';

import HamburgerMenu from './hamburger-menu';

type CustomProps = {
}

type CustomStates = {
  subMenuClassName: string[]
}

// eslint-disable-next-line
export default class extends React.Component<CustomProps, CustomStates> {

  constructor(props: any) {
    super(props)

    this.state = {
      subMenuClassName: this.createSubMenuClassName(),
    }
  }

  private createSubMenuClassName = (index?: number) => {
    let subMenuClassName: string[] = []
    let count = MenuListConf.length
    for (let i = 0; i < count; i++) {
      subMenuClassName[i] = ''
    }
    if (index !== undefined) {
      subMenuClassName[index] = ' ' + styles.openNavSubMenu
    }
    return subMenuClassName
  }

  private createMenuList = () => {
    let retMenuList: React.ReactElement[] = []
    let index1: number = 0
    for (let key1 in MenuListConf) {
      let tmp1 = MenuListConf[key1]
      if (tmp1.menuList === undefined) {
        let tmpAnchor
        if (tmp1.url.slice(0, 4) === 'http') {
          tmpAnchor = <a href={tmp1.url} className={`${styles.navLink}`}>{tmp1.linkName}</a>
        }
        else {
          tmpAnchor = <Link to={tmp1.url} className={`${styles.navLink}`}>{tmp1.linkName}</Link>
        }
        retMenuList[index1] =
            <li className={`${styles.navMenuItem}`} key={key1}>
              {tmpAnchor}
            </li>
      }
      else {
        let tmpMenuList: React.ReactElement[] = []
        let index2: number = 0
        for (let key2 in tmp1.menuList) {
          let tmp2 = tmp1.menuList[key2]
          let tmpAnchor
          if (tmp2.url.slice(0, 4) === 'http') {
            tmpAnchor = <a href={tmp2.url} className={`${styles.navSubLink}`}>{tmp2.linkName}</a>
          }
          else {
            tmpAnchor = <Link to={tmp2.url} className={`${styles.navSubLink}`}>{tmp2.linkName}</Link>
          }
          tmpMenuList[index2] =
            <li className={`${styles.navSubMenuItem}`} key={key2}>
              {tmpAnchor}
            </li>
          index2++
        }
        retMenuList[index1] =
          <li className={`${styles.navMenuItem} ${styles.navMenuSubWrapper}`} key={key1}>
            <button
              data-index={index1}
              onClick={this.handlerClick}
              className={`${styles.navSubMenuOpenButton} ${styles.navLink}`}
            >
              {tmp1.linkName}&nbsp;▼
            </button>
            <ul className={`${styles.navSubMenu}${this.state.subMenuClassName[index1]}`}>
              {tmpMenuList}
            </ul>
          </li>
      }
      index1++
    }

    return retMenuList
  }

  private handlerClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.index === undefined) {
      return
    }

    let index = parseInt(e.currentTarget.dataset.index)

    this.setState((state) => {
      if (state.subMenuClassName[index] === '') {
        return {subMenuClassName: this.createSubMenuClassName(index)}
      }
      else {
        return {subMenuClassName: this.createSubMenuClassName()}
      }
    })
  }

  public render = () => {
    const boundary:number = 682;
    
    return (
      <header id="header" className={`${LayoutStyles.header} ${styles.header}`}>
        <div id="header-logo" className={`${styles.headerLogo}`}>
          <Link to="/">
            <img src={icon}  alt="logo" className={`${styles.logoImage}`}/>
          </Link>
        </div>
        <MediaQuery minWidth={boundary}>
          <nav id="header-nav" className={`${styles.headerNav}`}>
            <ul className={`${styles.navMenu}`}>
              {this.createMenuList()}
            </ul>
          </nav>
        </MediaQuery>
        <MediaQuery maxWidth={boundary - 1}>
          <HamburgerMenu />
        </MediaQuery>
      </header>
    )
  }
}