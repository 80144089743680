import React from 'react';

import NormalPageTemplate from '../page-components/normal-page-template';

import icon from '../assets/okinari-icon-circle.png';

import styles from './about.module.css';

export default (() => {
  return (
    <NormalPageTemplate>
      <article className={`${styles.about}`}>
        <h1>About</h1>

        <section className={`${styles.profile}`}>
          <img src={icon} alt="profile" />
          <h3>Hi.</h3>
          <p className={`${styles.sectionContents}`}>
            I'm okinari, an engineer based in Fukuoka, Japan.
            I develop web apps and mobile native apps, do technical advisors, radio personalities, and more.
          </p>
        </section>

        <section className={`${styles.whatICanDo}`}>
          <h3>What I can do.</h3>
          <p className={`${styles.sectionContents}`}>
            I'm a system developer, so I know how to develop a system and how to use the system more efficiently.
            In addition, I am also accepting various consultations and requests regarding the system.
          </p>
        </section>
      </article>

      <article className={`${styles.iCanHelp}`}>
        <h2>I can help.</h2>
        <p>
          I take on a job request as a freelancer.
          If you have a system you want to develop, then get in touch.
          We welcome small things like "I want some help" or "I need some advice".
        </p>
      </article>
    </NormalPageTemplate>
  );
}) as React.FC;