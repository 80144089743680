import React from 'react';

import NormalPageTemplate from '../page-components/normal-page-template';

// import icon from '../assets/okinari-icon-circle.png';
import logoTwitter from '../assets/twitter-logo.png';

import styles from './contact.module.css';

// import TextInput from '../form-components/text-input';
// import TextareaInput from '../form-components/textarea-input';

export default (() => {
  return (
    <NormalPageTemplate>
      <article className={`${styles.articleContact}`}>
        <h1>Contacts</h1>
        <p>
          お問い合わせは、TwitterもしくはMailにてお願いします。
          なお、こちらには記載しておりませんが、面識のある方はFacebook Messagerでご連絡いただくことも可能です。
        </p>
        
        {/* <form action="" className={`${styles.formArea}`}>
          <TextInput id="name" label="Name" isRequired={true} placeholder="John Smith" />
          <TextInput id="email" label="E-mail" isRequired={true} placeholder="example@example.com" />
          <TextareaInput id='value' label='Inquiry details' isRequired={true} placeholder="Please tell me!" />
        </form> */}

        {/* <img src={icon} alt="profile" /> */}

        <p>
          <img src={logoTwitter} alt='twitter logo' className={`${styles.twitterIcon}`} />
          Twitter: <a href="https://tw.okinari.com/" target="_blank" rel="noopener noreferrer">@okinari</a>
        </p>
        <p>
          Mail: <a href="mailto:contact@okinari.com" target="_blank" rel="noopener noreferrer">contact@okinari.com</a>
        </p>
        
      </article>
    </NormalPageTemplate>
  );
}) as React.FC;