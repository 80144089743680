import React from 'react';

import AbstractInput, { AbstractInputProps } from './abstract-input';

import FormStyles from './form.module.css';

type CustomProps = AbstractInputProps['props'] & {
  value?: string
  defaultValue?: string
  list?: string
  placeholder?: string
}

export default ((props: CustomProps) => {
  return (
    <AbstractInput props={props}>
      <input
        type="text"
        id={props.id}
        name={props.id}
        value={props.value}
        defaultValue={props.defaultValue}
        
        onChange={props.onChange}

        list={props.list}
        readOnly={props.readOnly}

        placeholder={props.placeholder}
        className={`${FormStyles.formControll}`}
      />
    </AbstractInput>
  );
}) as React.FC<CustomProps>