import React from 'react';
import NormalPageTemplate from '../page-components/normal-page-template';
import styles from './not-found.module.css';

export default (() => {
  return (
    <NormalPageTemplate>
      <div className={`${styles.notFound}`}>
        Not Found
      </div>
    </NormalPageTemplate>
  );
}) as React.FC;