import React from 'react';

import LayoutStyles from './layout.module.css';

export default (() => {
  return (
    <div id="advertisement" className={`${LayoutStyles.advertisement}`}>
      Advertisement
    </div>
  );
}) as React.FC;