export enum GoogleCalendarType {
	PersonalOkinariPrivate,
	PersonalPublic,
	PublicEvents
}

// The name(title) is not show
export const GoogleCalendarList = {
	// [GoogleCalendarType.PersonalOkinariPrivate]: {
	//   name: 'Personal',
	//   idList: [
	//     'b2tpbmFyaS5qcF85amMzdjExaW40a2d2aWs0ZWI3Nms5MDVqb0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t', // Personal
	//     'b2tpbmFyaS5qcF85MTc5YmVvYjg0bjF1NmlmODNyZG5tMTRrb0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t', // TensitiveSchedule
	//     'b2tpbmFyaS5qcF9la2tlM2JuZjk2NDFqaXBhY2lxNnFsaWJoY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t', // logicommon
	//     'b2tpbmFyaS5vb2JhQGthcmFiaW5lci50ZWNo', // Karabiner
	//     'b2tpbmFyaS5qcF9pNnY1YjAxc25vb3I3MzE2NDJsczc1bjQ4a0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t', // oolab
	//     'ZjJwYjdlaDgyamdrZHZxMmo3NWwzczE0cmw2cWd1M2hAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20', // connpass
	//   ],
	// },
	[GoogleCalendarType.PersonalOkinariPrivate]: {
		name: 'Personal',
		idList: [
			'b29iYUBva2luYXJpLmpw', // MainCalendar
		],
	},
	[GoogleCalendarType.PersonalPublic]: {
		name: 'Personal',
		idList: [
			'b2tpbmFyaS5qcF9rcTAxZ3B1aWR1dDhuZXZzcmRiNHRlc3NlZ0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t', // [Public]Personal
		],
	},
	[GoogleCalendarType.PublicEvents]: {
		name: 'Events',
		idList: [
			'b2tpbmFyaS5qcF9pa2NucDMzcXU4YWJpcXEzYWVqdWR1ajkxY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t', // [Public]Events
		],
	}
}

export const GoogleCalendarUrlParamList: { [key: string]: GoogleCalendarType } = {
	'personal': GoogleCalendarType.PersonalOkinariPrivate,
	'private': GoogleCalendarType.PersonalOkinariPrivate,
	'personal-for-karabiner': GoogleCalendarType.PersonalOkinariPrivate,
	'events': GoogleCalendarType.PublicEvents,
	'event': GoogleCalendarType.PublicEvents,
}