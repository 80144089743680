import React from 'react'
import { Link } from 'react-router-dom'

import styles from './hamburger-menu.module.css'

import { MenuListConf } from '../config/menu-list-conf'

type CustomStates = {
  modalStyle: string
  menuTriggerStyle: string
}

// eslint-disable-next-line
export default class extends React.Component<any, CustomStates> {

  private menuList: React.ReactElement[] = [];

  constructor(props: any) {
    super(props)
    
    this.state = {
      modalStyle: styles.inactive,
      menuTriggerStyle: '',
    }

    let index1: number = 0;
    for (let key1 in MenuListConf) {
      let tmp1 = MenuListConf[key1];
      if (tmp1.menuList === undefined) {
        this.menuList[index1] = 
          <li className={`${styles.navMenuItem}`} key={key1}>
            <Link to={tmp1.url} className={`${styles.navLink}`} onClick={this.changeModal}>{tmp1.linkName}</Link>
          </li>
      }
      else {
        let tmpMenuList: React.ReactElement[] = [];
        let index2: number = 0;
        for (let key2 in tmp1.menuList) {
          let tmp2 = tmp1.menuList[key2]
          tmpMenuList[index2] =
            <li className={`${styles.navSubMenuItem}`} key={key2}>
              <Link to={tmp2.url} className={`${styles.navSubLink}`} onClick={this.changeModal}>{tmp2.linkName}</Link>
            </li>
          index2++
        }
        this.menuList[index1] =
          <li className={`${styles.navMenuItem} ${styles.navMenuSubWrapper}`} key={key1}>
            <span className={`${styles.navLink} ${styles.navSubMenuItem}`}>
              [{tmp1.linkName}]
            </span>
            <ul className={`${styles.navSubMenu}`}>
              {tmpMenuList}
            </ul>
          </li>
      }
      index1++
    }
  }

  changeModal = () => {
    this.setState((state) => {
      if (state.modalStyle === styles.active) {
        return {
          modalStyle: styles.inactive,
          menuTriggerStyle: '',
        }
      }
      else {
        return {
          modalStyle: styles.active,
          menuTriggerStyle: styles.menuTriggerActive,
        }
      }
    })
  }

  render() {
    return (
      <div>
        <button className={`${styles.menuTrigger} ${this.state.menuTriggerStyle}`} onClick={this.changeModal}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className={`${styles.modal} ${this.state.modalStyle}`}>
          <ul className={`${styles.navMenu}`}>
            {this.menuList}
          </ul>
        </div>
      </div>
    );
  }
};